/* eslint-disable @next/next/no-img-element */
import React from 'react'
import Link from 'next/link'
import useMediaQuery from '@/utils/useMediaQuery'
import { FaPhoneAlt, FaBars } from 'react-icons/fa'
import { ImgResponsive } from '@/components/elements'
import { StyleHeader } from './style'

type HeaderProps = {
  isActive: boolean
  setIsActive: (isActive: boolean) => void
}

const Header = (props: HeaderProps) => {
  const { isActive, setIsActive } = props
  const isBreakpoint = useMediaQuery(768)
  return (
    <StyleHeader className='header-temp-wrap'>
      <div className='header-temp mx-auto max-w-screen-3xl'>
        <div className='flex justify-between px-4'>
          <div className='header-temp__logo'>
            <Link href='/' className='header-temp__logo-link'>
              <ImgResponsive
                src='/images/Logo-2020-Black.webp'
                alt='Twin Synergy รับพัฒนาเว็บไซต์, แอปพลิเคชั่น และออกแบบเว็บไซต์'
                width={198}
                height={42}
              />
            </Link>
          </div>
          {!isBreakpoint ? (
            <div className='header-temp__menu hidden lg:block'>
              <ul>
                <li className='header-temp__menu-item'>
                  <Link href='/' className='header-temp__menu-link uppercase'>
                    Home
                  </Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/services' className='header-temp__menu-link uppercase'>
                    Services
                  </Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/blog' className='header-temp__menu-link uppercase'>
                    Article
                  </Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/portfolio' className='header-temp__menu-link uppercase'>
                    Portfolio
                  </Link>
                </li>
                <li className='header-temp__menu-item'>
                  <Link href='/contact' className='header-temp__menu-link uppercase'>
                    Contact
                  </Link>
                </li>
              </ul>
              <a
                href='tel:0637899059'
                className='ml-14 bg-white p-3.5'
                rel='nofollow'
                aria-label='Phone btn'
              >
                <FaPhoneAlt className='text-primary' />
              </a>
            </div>
          ) : (
            <button
              className='header-temp__menu-btn bg-white px-3.5'
              onClick={() => setIsActive(!isActive)}
              aria-label='Hamburger btn'
            >
              <FaBars className='text-primary' />
            </button>
          )}
        </div>
      </div>
    </StyleHeader>
  )
}

export default Header
