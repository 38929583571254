/* eslint-disable no-undef */
import React from 'react'
import Head from 'next/head'

export type preloadProps = {
  href: string
  imageSrcSet: string
  id: number
}

type SeoHeadProps = {
  title?: string
  description?: string
  image?: string
  url?: string
  type?: string
  publishDate?: string
  preload?: preloadProps[]
}

const SeoHead = (props: SeoHeadProps) => {
  const {
    title = 'Twin Synergy รับทำเว็บไซต์ และแอปพลิเคชัน',
    description = 'รับทำเว็บไซต์ และแอปพลิเคชันโดยทีมงานมืออาชีพ ติดต่อได้ที่เบอร์ 063-7899059',
    url = process.env.WEBSITE_URL,
    image = 'https://twinsynergy.co.th/images/social-home.webp',
    type = 'website',
    publishDate,
    preload,
  } = props

  const router = typeof window !== 'undefined' && window.location.pathname
  const cleanPath = router ? encodeURI(router.split('#')[0].split('?')[0]) : ''
  const canonicalUrl = `${url?.replace(/\/$/, '')}${cleanPath}`
  const metaDesc150 =
    typeof description === 'string'
      ? description
          .replace(/<\/?[^>]+(>|$)/g, '')
          .replace(/&(nbsp|amp|quot|lt|gt);/g, '')
          .substring(0, 200)
          .trim()
      : description

  return (
    <Head>
      <title>{title}</title>
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta name='robots' content='max-image-preview:large' />
      <meta name='description' content={metaDesc150} />
      <meta name='publish-date' content={publishDate} />
      <link rel='canonical' href={canonicalUrl} />
      <meta property='fb:app_id' content='xxxxxxx' />
      <meta property='og:type' content={type === 'article' ? 'article' : 'website'} />
      <meta property='og:site_name' content='Twin Synergy' />
      <meta property='og:url' content={canonicalUrl} />
      <meta property='og:description' content={metaDesc150} />
      <meta property='og:title' content={title} />
      <meta property='article:published_time' content={publishDate} />
      {image !== undefined && <meta property='og:image' content={image} />}
      <meta name='twitter:url' content={canonicalUrl} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={metaDesc150} />
      {image !== undefined && <meta name='twitter:image' content={image} />}
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.webp' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.webp' />
      <link rel='apple-touch-icon' href='/apple-touch-icon.webp' />
      <link rel='icon' type='image/png' sizes='192x192' href='/android-chrome-192x192.webp' />
      {preload &&
        preload.length > 0 &&
        preload.map((i) => (
          <link key={i.id} rel='preload' as='image' href={i.href} imageSrcSet={i.imageSrcSet} />
        ))}
    </Head>
  )
}

export default SeoHead
