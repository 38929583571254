import React, { CSSProperties, useEffect, useState } from 'react'
import { StyleBadge } from './style'
import classNames from 'classnames'

export type Colors =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | undefined
export type Overlap = 'circular' | 'square'
export type Placement = 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'custom'

interface BadgeProps {
  children?: React.ReactNode
  content?: React.ReactNode | string | number
  placement?: Placement
  overflowCount?: number
  color?: Colors
  shape?: Overlap
  chips?: boolean
  wrapperClassName?: string
  placementClassName?: string
  styles?: CSSProperties
}

const Badge: React.FC<BadgeProps> = (props) => {
  const {
    chips = false,
    children,
    content,
    overflowCount = 99,
    placement = 'top-end',
    color,
    shape = 'square',
    wrapperClassName,
    placementClassName,
    styles,
  } = props
  // NOSONAR
  const [isOpen, setIsOpen] = useState(false)

  const badgeDismiss = () => setIsOpen(false)

  const numberedDisplayCount = (content as number) > overflowCount ? `${overflowCount}+` : content

  const colorChip = classNames({
    [`chip chip-primary`]: color === 'primary' && color === undefined,
    [`chip chip-secondary`]: color === 'secondary',
    [`chip chip-success`]: color === 'success',
    [`chip chip-error`]: color === 'error',
    [`chip chip-warning`]: color === 'warning',
    [`chip chip-info`]: color === 'info',
    [`chip chip-light`]: color === 'light',
    [`chip chip-dark`]: color === 'dark',
  })

  useEffect(() => {
    if (chips === true) {
      setIsOpen(true)
    }
  }, [chips])

  const badgeShape = classNames({
    [`badge-circular`]: shape === 'circular',
    [`badge-square`]: shape === 'square',
  })

  const bageTextColor = classNames(
    'badge',
    {
      [`badge-primary`]: color === 'primary' && color !== undefined,
      [`badge-secondary`]: color === 'secondary',
      [`badge-success`]: color === 'success',
      [`badge-error`]: color === 'error',
      [`badge-warning`]: color === 'warning',
      [`badge-info`]: color === 'info',
      [`badge-light`]: color === 'light',
      [`badge-dark`]: color === 'dark',
    },
    badgeShape,
  )

  const badgePlacementCls = classNames(
    `absolute min-w-[12px] min-h-[12px] rounded-full py-1 px-1 text-xs font-medium content-[''] leading-none grid place-items-center`,
    {
      [`${placementClassName}`]: placement === 'custom',
      'top-[4%] left-[2%] -translate-x-2/4 -translate-y-2/4': placement === 'top-start',
      'top-[4%] right-[2%] translate-x-2/4 -translate-y-2/4':
        placement === 'top-end' && placement === undefined,
      'bottom-[4%] left-[2%] -translate-x-2/4 translate-y-2/4': placement === 'bottom-start',
      'bottom-[4%] right-[2%] translate-x-2/4 translate-y-2/4': placement === 'bottom-end',
    },
    bageTextColor,
  )

  let result

  if (chips === false && (content as number) > overflowCount) {
    result = (
      <div
        className={classNames({
          [`${wrapperClassName}`]: wrapperClassName,
          'relative inline-flex': !wrapperClassName,
        })}
      >
        {children}
        {content !== undefined && content !== null && (
          <StyleBadge className={badgePlacementCls} style={styles}>
            {numberedDisplayCount}
          </StyleBadge>
        )}
      </div>
    )
  } else if (chips === false && typeof content === 'string' && content) {
    result = (
      <div className={wrapperClassName ?? 'relative inline-flex'}>
        <StyleBadge className={bageTextColor} style={styles}>
          {content}
        </StyleBadge>
      </div>
    )
  } else if (chips === false && content === undefined) {
    result = (
      <div className={wrapperClassName ?? 'relative inline-flex'}>
        <StyleBadge className={badgePlacementCls} style={styles} />
      </div>
    )
  } else {
    result = (
      <StyleBadge
        id='badge-dismiss-default'
        className={`${isOpen ? 'inline-flex' : 'hidden'} mr-2 items-center rounded px-2 py-1 text-sm font-medium ${colorChip}`}
        style={styles}
      >
        {children ?? content}
        <div
          aria-hidden='true'
          className='ml-2 inline-flex items-center rounded-sm bg-transparent p-0.5 text-sm'
          data-dismiss-target='#badge-dismiss-default'
          aria-label='Remove'
          onClick={badgeDismiss}
        >
          <svg
            aria-hidden='true'
            className='h-3.5 w-3.5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
          <span className='sr-only'>Remove badge</span>
        </div>
      </StyleBadge>
    )
  }

  return result
}

export default Badge
